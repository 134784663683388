import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    {/* <span className="sub-title">
                        <img src={starIcon} alt="icon" /> 
                        Our Solutions
                    </span> */}
                    <h2 className="homepage-tittle">Зошто Tafoma?</h2>
                    <p className="purple-text">Платформа наменета специјално за мали и средни претпријатија.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <StaticImage src={'../../assets/images/homepage/cut-cost.png'} 
                                        alt="Money stays in the wallet." 
                                        className="single-solutions-box-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                            </div>
                            <h3>
                            Намалени трошоци
                            </h3>
                            <p>Зошто да плаќате за 3 или 4 софтвери, кога можете да користите софтвер која ги обезбедува сите карактеристики на едно место за една цена.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <StaticImage src={'../../assets/images/homepage/no-integration.png'} 
                                        alt="Person chosing from many options." 
                                        className="single-solutions-box-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        placeholder="none"/>
                            </div>
                            <h3>
                            Без интеграции 
                            </h3>
                            <p>Нема потреба да трошите време и напор за интегрирање на алатките кои ви се потребни, Tafoma ги обезбедува сите.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <StaticImage src={'../../assets/images/homepage/improves-productivity.png'} 
                                        alt="Person next to speedometer." 
                                        className="single-solutions-box-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        placeholder="none"/>
                            </div>
                            <h3>
                            Ја подобрува продуктивноста
                            </h3>
                            <p>Tafoma е најлесниот начин за да ја подобрите продуктивноста на вашите тимови, без разлика дали работите remote или не.</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions